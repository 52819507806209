






import { Component } from 'vue-property-decorator'
import Base from '@/application/models/Base'
import ManagePatient from '@/components/manage/custom/ManagePatient.vue'
import Patient from '@/application/models/Patient'
import { Getter } from 'vuex-class'
import PatientVitalsSources from '@/application/models/PatientVitalsSources'

@Component({
  components: { ManagePatient }
})
export default class Manage extends Base {
  constructor () {
    super('manage')
  }
}
