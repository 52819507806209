var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.getLangText('title'))+" "),_c('add-patient')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.patients,"no-data-text":_vm.getLangTextWithoutPrefix('app.table.no-data-text'),"no-results-text":_vm.getLangTextWithoutPrefix('app.table.no-results-text'),"footer-props":{
          'items-per-page-text': _vm.getLangTextWithoutPrefix('app.table.items-per-page-text'),
          'items-per-page-all-text': _vm.getLangTextWithoutPrefix('app.table.items-per-page-all-text')
        }},scopedSlots:_vm._u([{key:"item.timeFrom",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prettyDate(item.timeFrom))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('discharge-patient',{attrs:{"patient":item.patient}})]}},{key:"footer.page-text",fn:function(footer){return [_vm._v(" "+_vm._s(footer.pageStart + '-' + footer.pageStop + _vm.getLangTextWithoutPrefix('app.table.pagination-from-text') + footer.itemsLength)+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }