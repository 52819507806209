




































import { Component } from 'vue-property-decorator'
import Base from '@/application/models/Base'
import { DataTableHeader } from 'vuetify'
import { Getter } from 'vuex-class'
import Patient from '@/application/models/Patient'
import AddPatient from '@/components/common/AddPatient.vue'
import Tooltip from '@/components/common/Tooltip.vue'
import DischargePatient from '@/components/common/DischargePatient.vue'

@Component({
  components: { DischargePatient, Tooltip, AddPatient }
})
export default class ManagePatient extends Base {
  @Getter('patients', { namespace: 'patients' })
  readonly patients!: Array<Patient>

  constructor () {
    super('manage.managePatient')
  }

  get tableHeaders (): DataTableHeader[] {
    return [
      {
        text: this.getLangText('table.id'),
        value: 'patient.id'
      },
      {
        text: this.getLangText('table.fullname'),
        value: 'patient.fullName'
      },
      {
        text: this.getLangText('table.admited'),
        value: 'timeFrom'
      },
      {
        text: this.getLangText('table.actions'),
        value: 'actions'
      }
    ]
  }
}
